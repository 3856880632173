<template>
  <div>
    <section-download-file
      :title="translations.certificatesPolicy"
      :file="getImgUrl(translations.certificatesPolicyFile)"
    />
  </div>
</template>

<script>
  import SectionDownloadFile from '@/views/sections/reusable/DownloadFile'
  import { STORE_NAMESPACE_CERTIFICATES } from '@/views/certificates/Index.vue'
  import { mapState } from 'vuex'
  import getImgUrl from '@/utils/getImgUrl.js'

  export default {
    name: 'CertificatesPolicyFiles',
    components: {
      SectionDownloadFile,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_CERTIFICATES, [
        'translations',
      ]),
    },
    methods: {
      getImgUrl,
    },
    data: () => ({
      references: [
        {
          title: 'ZAPOZNAJ SIĘ Z NASZĄ POLITYKĄ JAKOŚCI / ŚRODOWISKA / BHP',
          file: 'certificates/politykajakosci.jpg',
        },
      ],
    }),
  }
</script>
