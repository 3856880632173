<template>
  <v-theme-provider dark>
    <base-section
      class="secondary"
      space="40"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="2">
            <base-img
              v-if="img"
              max-width="100%"
              :src="img"
              max-height="60px"
            />
          </v-col>
          <v-col
            cols="8"
            style="display: flex; align-items: center"
          >
            <base-title
              :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
              :title="title"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <base-btn
              :block="$vuetify.breakpoint.smAndDown"
              color="white"
              target="_blank"
              large
              outlined
              @click="openFile"
            >
              {{ translations.downloadFile }}
            </base-btn>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_ALL } from '@/App.vue'

  export default {
    name: 'SectionDownloadFile',
    props: {
      title: {
        type: String,
      },
      file: {
        type: String,
      },
      img: {
        type: String,
      },
    },
    computed: {
      ...mapState(STORE_NAMESPACE_ALL, [
        'translations',
      ]),
    },
    methods: {
      openFile () {
        window.open(this.file)
      },
    },
  }
</script>
